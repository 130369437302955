





















































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { BUSINESS_COUNTRIES } from '@/country-list';
import RegisterAccount from '@/components/Register.vue';
import { ApiResource, SelectOption } from '@/types';
import { StripeError, Token, TokenCreateParams } from '@stripe/stripe-js';

interface Form {
  name: string,
  vanity: string,
  country: string | null,
  type: 'individual' | 'company',
  // eslint-disable-next-line camelcase
  accepts_terms: boolean,
  // eslint-disable-next-line camelcase
  stripe_account_token: Token | null,
}

interface Validation {
  [key: string]: string | null,
  name: string | null,
  vanity: string | null,
  country: string | null,
  type: string | null,
  // eslint-disable-next-line camelcase
  accepts_terms: string | null,
  // eslint-disable-next-line camelcase
  stripe_account_token: string | null,
}

function vanify(str: string): string {
  return str.toLowerCase()
    .replace(/\s+/g, '') // Spaces
    .replace(/[^\w-]+/g, '') // Non-Word chars & -
    .substring(0, 35);
}

export default Vue.extend({
  components: {
    TermsV1: () => import(/* webpackChunkName: "l-agb-v1" */ '@/components/legal/agb/V1.vue'),
    RegisterAccount,
  },
  data() {
    return {
      error: null as Error | StripeError | null,
      form: {
        name: '',
        vanity: '',
        country: this.$store.getters.COUNTRY,
        type: 'individual',
        accepts_terms: false,
        stripe_account_token: null,
      } as Form,
      loading: false,
      pricingWithFlex: false,
      pricingWithPlus: false,
      step: 0,
      termsSize: 'normal',
      validation: {
        name: null,
        vanity: null,
        country: null,
        type: null,
        accepts_terms: null,
      } as Validation,
    };
  },
  computed: {
    ...mapGetters({
      account: 'account/Account',
      isAuthenticated: 'account/IsLoggedIn',
      isVanityAvailable: 'vanity/IsAvailable',
      isVanityChecked: 'vanity/IsChecked',
      findVanity: 'vanity/Find',
    }),
    BASE_URL(): string {
      return process.env.VUE_APP_BASE_URL || '';
    },
    countryOptions(): SelectOption[] {
      const options: SelectOption[] = [];

      BUSINESS_COUNTRIES.forEach((country) => {
        options.push({
          value: country,
          text: this.$t(`country.${country}`),
        });
      });

      options.sort((a, b) => {
        if (a.text < b.text) return -1;
        if (a.text > b.text) return 1;
        return 0;
      });

      return options;
    },
    isAccountEmailVerified(): boolean {
      if (!this.isAuthenticated) return false;
      return (this.account as ApiResource.Account).is_email_verified;
    },
    pricingId(): string {
      let pricing = 'v2.base';

      if (this.pricingWithFlex) {
        pricing += '+flex';
      }
      if (this.pricingWithPlus) {
        pricing += '+plus';
      }

      return pricing;
    },
    pricingFeePercent(): number {
      let percent = 15;

      if (this.pricingWithFlex) percent += 5;
      if (this.pricingWithPlus) percent += 5;

      return percent;
    },
  },
  watch: {
    '$route.query.flex': {
      immediate: true,
      handler(to) {
        if (to) this.pricingWithFlex = true;
      },
    },
    '$route.query.plus': {
      immediate: true,
      handler(to) {
        if (to) this.pricingWithPlus = true;
      },
    },
  },
  async beforeMount() {
    const name = window.sessionStorage.getItem('create.name');
    if (name && name.length <= 35) this.form.name = name;
    const vanity = window.sessionStorage.getItem('create.vanity');
    if (vanity && vanity.length <= 35) this.form.vanity = vanity;
    const type = window.sessionStorage.getItem('create.type');
    if (type === 'company' || type === 'individual') this.form.type = type;
  },
  beforeDestroy() {
    window.sessionStorage.setItem('create.name', this.form.name);
    window.sessionStorage.setItem('create.vanity', this.form.vanity);
    window.sessionStorage.setItem('create.type', this.form.type);
  },
  methods: {
    handleError(e?: Error|StripeError): void {
      if (!e) return;
      this.error = e;

      // this.$toast({
      //   message: e.message,
      //   classes: 'is-danger',
      //   position: 'top',
      // });
    },
    handleValidationError(v: Validation): void {
      if (v.name) this.validation.name = v.name;
      if (v.vanity) this.validation.vanity = v.vanity;
      if (v.country) this.validation.country = v.country;
      if (v.type) this.validation.type = v.type;
      if (v.accepts_terms) this.validation.accepts_terms = v.accepts_terms;
    },
    async submitName(): Promise<void> {
      this.loading = true;
      this.form.vanity = await this.prefillVanity();
      this.step = 1;
      this.loading = false;
    },
    async prefillVanity(): Promise<string> {
      const originalVanifiedName = vanify(this.form.name);
      let vanity = originalVanifiedName;

      const queryVanity = this.$route.query.vanity;

      if (queryVanity) {
        if (Array.isArray(queryVanity)) {
          // eslint-disable-next-line prefer-destructuring
          if (queryVanity[0]) vanity = queryVanity[0];
        } else vanity = queryVanity;
      }

      await this.$store.dispatch('vanity/Check', vanity).catch(() => { /**/ });

      const maxAttempts = 5;
      let attempt = 0;

      while (!this.isVanityAvailable(vanity) && attempt < maxAttempts) {
        vanity = `${vanity}1`;

        // eslint-disable-next-line no-await-in-loop
        await this.$store.dispatch('vanity/Check', vanity).catch(() => { /**/ });
        attempt += 1;
      }

      return attempt >= maxAttempts ? originalVanifiedName : vanity;
    },
    async submitVanity(): Promise<void> {
      this.loading = true;
      const { vanity } = this.form;

      if (!this.isVanityChecked(vanity)) {
        await this.$store.dispatch('vanity/Check', vanity).catch(this.handleError);
      }

      if (!this.isVanityAvailable(vanity)) {
        this.validation.vanity = this.findVanity(vanity).reason;

        this.loading = false;
        return;
      }

      // If not yet prefilled
      if (!this.form.country) this.form.country = await this.prefillCountry();
      this.step = 2;
      this.loading = false;
    },
    async checkVanity(): Promise<boolean> {
      this.loading = true;
      this.validation.vanity = null;

      if (!this.form.vanity) {
        this.validation.vanity = 'notOptional';
        this.loading = false;
        return false;
      }
      if (this.form.vanity.length > 35) {
        this.validation.vanity = 'length';
        this.loading = false;
        return false;
      }

      const result = await this.$store.dispatch('vanity/Check', this.form.vanity).catch(this.handleError);

      if (!result) {
        this.loading = false;
        return false;
      }

      this.validation.vanity = result.reason;
      this.loading = false;
      return true;
    },
    async prefillCountry(): Promise<string|null> {
      const storedCountry = this.$store.getters.COUNTRY;

      if (storedCountry) return storedCountry;

      await this.$store.dispatch('info').catch(this.handleError);

      return this.$store.getters.COUNTRY || null;
    },
    async submitRequired(): Promise<void> {
      this.loading = true;
      this.validation.country = null;
      this.validation.type = null;

      if (!this.checkCountry() || !this.checkType()) {
        this.loading = false;
        return;
      }

      this.step = 3;
      this.loading = false;
    },
    checkCountry(): boolean {
      const { country } = this.form;

      if (!country) {
        this.validation.country = 'notOptional';
        return false;
      }

      if (!BUSINESS_COUNTRIES.includes(country)) {
        this.validation.country = 'in';
        return false;
      }

      return true;
    },
    checkType(): boolean {
      const { type } = this.form;

      if (!type) {
        this.validation.type = 'notOptional';
        return false;
      }

      if (!['company', 'individual'].includes(type)) {
        this.validation.type = 'in';
        return false;
      }

      return true;
    },
    handleRegisteredAccount(): void { /* */ },
    async checkAccountEmailVerificationStatus(): Promise<void> {
      this.loading = true;

      await this.$store.dispatch('account/GetAccount').catch(this.handleError);

      this.loading = false;
    },
    async submitTerms(): Promise<void> {
      this.loading = true;
      this.validation.accepts_terms = null;

      if (!this.form.accepts_terms) {
        this.loading = false;
        this.validation.accepts_terms = 'boolVal';
        return;
      }

      const stripe = await this.$stripe().catch((e) => {
        this.loading = false;
        this.handleError(e);
      });

      if (!stripe) return;

      const params: TokenCreateParams.Account = {
        business_type: this.form.type,
        tos_shown_and_accepted: this.form.accepts_terms,
      };

      const { token, error } = await stripe.createToken('account', params);

      if (error || !token) {
        this.loading = false;
        this.handleError(error);
        return;
      }

      this.form.stripe_account_token = token;
      this.step = 4;
      this.loading = false;
    },
    async submit(): Promise<void> {
      this.loading = true;

      const data = await this.$store.dispatch('account/CreateBusiness', {
        form: {
          country: this.form.country,
          name: this.form.name,
          vanity: this.form.vanity,
          accepts_terms: this.form.accepts_terms,
          stripe_account_token: this.form.stripe_account_token?.id,
          pricing_id: this.pricingId,
        },
      }).catch(this.handleError);

      this.loading = false;

      if (!data) return;

      this.$a.goal(this.$a.goals.MakerCreate);

      this.$router.push({
        name: 'dashboard.welcome',
        params: { VANITY: data.data.vanity },
      });
    },
  },
});
