import { TranslateResult } from 'vue-i18n';
import { Rule, ValidationError, Validator } from './types';

type ErrorMessage = ValidationError | TranslateResult;

export function rule(validator: Validator, error: ValidationError | TranslateResult): Rule {
  return (input: any) => (validator(input) ? undefined : `${error}`);
}

function r2v(r: Rule): Validator {
  return (v) => r(v) === undefined;
}

export function required(m: ErrorMessage): Rule {
  return rule((v) => !!v, m);
}

export function minLength(minLen: number, m: ErrorMessage): Rule {
  return rule((v) => v.length >= minLen, m);
}

export function maxLength(maxLen: number, m: ErrorMessage): Rule {
  return rule((v) => v.length <= maxLen, m);
}

export function composite(validators: Validator[], m: ErrorMessage): Rule {
  return rule((v) => validators.every((r) => r(v)), m);
}

export function length(minLen: number, maxLen: number, m: ErrorMessage): Rule {
  return composite([
    r2v(minLength(minLen, m)),
    r2v(maxLength(maxLen, m)),
  ], m);
}

export function trueVal(m: ErrorMessage): Rule {
  return rule((v) => v === true, m);
}

export function min(minNum: number, m: ErrorMessage): Rule {
  return rule((v) => v >= minNum, m);
}

export function max(maxNum: number, m: ErrorMessage): Rule {
  return rule((v) => v <= maxNum, m);
}

export function between(minNum: number, maxNum: number, m: ErrorMessage): Rule {
  return composite([
    r2v(min(minNum, m)),
    r2v(max(maxNum, m)),
  ], m);
}

export function inArray(arr: Array<any>, m: ErrorMessage): Rule {
  return rule((v) => arr.includes(v), m);
}

export default {
  required,
};
