import { AxiosError } from 'axios';
import { TranslateResult } from 'vue-i18n';
import { Rule, Validatable, ValidationError } from './types';

export function validateRules(input: any, rules: Rule[]): ValidationError | undefined {
  let error: ValidationError | undefined;

  for (let i = 0; i < rules.length; i += 1) {
    error = rules[i](input);

    if (error) break;
  }

  return error;
}

export function validateComponent(component: Validatable | undefined, input: any, rules: Rule[]): boolean {
  if (!component) return true;
  return component.$v(validateRules(input, rules));
}

type ValidationSet = [Validatable, any, Rule[]];

export function validateComponents(...sets: ValidationSet[]): boolean {
  const results: boolean[] = [];
  sets.forEach(([c, i, r]) => results.push(validateComponent(c, i, r)));
  return results.every((v) => v);
}

type FieldName = string;

type ValidationErrorMap = Record<FieldName, ValidationError | undefined>;

type ComponentMap = Record<FieldName, Validatable>;

export function reportErrors(components: ComponentMap, errors: ValidationErrorMap): void {
  const fields = Object.keys(components);
  fields.forEach((field) => {
    components[field].$v(errors[field]);
  });
}

export function handleApiValidationError(
  error: AxiosError,
  components: ComponentMap,
  $t: (error: string) => TranslateResult,
): void {
  if (!error.response) throw error;
  if (error.response.status !== 422) throw error;
  const { data } = error.response;
  if (!data) throw error;
  if (!data.error) throw error;
  if (!data.error.fields) throw error;
  const errors: ValidationErrorMap = data.error.fields;

  const fields = Object.keys(components);
  fields.forEach((field) => {
    const fieldError = errors[field];
    const component = components[field];
    if (fieldError) component.$v(`${$t(`V.${field}.${fieldError}`)}`);
    else if (component) component.$v();
    else console.debug('$v: Component not found for field', field);
  });
}

/**
 * Wraps handleApiValidationError so that it can be passed into Promise.catch()
 */
export function validationErrorHandler(
  components: ComponentMap,
  $t: (error: string) => TranslateResult,
): ((error: AxiosError) => void) {
  return (e: AxiosError) => handleApiValidationError(e, components, $t);
}
