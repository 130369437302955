











































































































/* eslint-disable camelcase */
import Vue from 'vue';
import { mapGetters } from 'vuex';
import CountrySetting from '@/components/settings/Country.vue';
import { getResolvableLocaleLocation } from '@/router';
import { I18nLocale } from '@/types';
import { validateComponent, validationErrorHandler } from '@/validation';
import { maxLength, required, trueVal } from '@/validation/rules';
import { Validatable } from '@/validation/types';

interface Form {
  [index: string]: string|boolean,
  email: string,
  password: string,
  name: string,
  accepts_terms: boolean,
}

export default Vue.extend({
  components: {
    CountrySetting,
  },
  props: {
    noTitle: Boolean,
    noPassword: Boolean,
    instantLogin: Boolean,
    prefillName: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      error: null,
      form: {
        email: '',
        password: '',
        name: this.prefillName,
        accepts_terms: false,
      } as Form,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      country: 'COUNTRY',
    }),
    countryInput(): Validatable {
      return (this.$refs.country as Vue).$refs.select as Validatable;
    },
  },
  methods: {
    check(): boolean {
      return validateComponent(
        this.$refs.name as Validatable,
        this.form.name, [
          required(this.$t('V.name.notOptional')),
          maxLength(25, this.$t('V.name.length')),
        ],
      ) && validateComponent(
        this.$refs.email as Validatable,
        this.form.email, [
          required(this.$t('V.email.notOptional')),
          maxLength(255, this.$t('V.email.length')),
        ],
      ) && validateComponent(
        this.$refs.password as Validatable,
        this.form.password, [
          required(this.$t('V.password.notOptional')),
          maxLength(255, this.$t('V.password.length')),
        ],
      ) && validateComponent(
        this.countryInput,
        this.country, [
          required(this.$t('V.country.notOptional')),
        ],
      ) && validateComponent(
        this.$refs.terms as Validatable,
        this.form.accepts_terms, [
          required(this.$t('V.accepts_terms.notOptional')),
          trueVal(this.$t('V.accepts_terms.trueVal')),
        ],
      );
    },
    async submit(): Promise<void> {
      if (!this.check()) return;

      this.isLoading = true;

      const { email, password } = this.form;

      const account = await this.$store.dispatch('auth/register', {
        email,
        password: this.noPassword ? null : password,
        name: this.form.name,
        accepts_terms: this.form.accepts_terms,
        locale: this.$root.$i18n.locale,
        country: this.country,
        ab_segment: this.$store.getters.AB_SEGMENT,
      })
        .catch(validationErrorHandler({
          name: this.$refs.name as Validatable,
          email: this.$refs.email as Validatable,
          password: this.$refs.password as Validatable,
          accepts_terms: this.$refs.terms as Validatable,
          country: this.countryInput as Validatable,
        }, (m) => this.$t(m)))
        .catch((e) => { this.error = e; });

      this.isLoading = false;

      if (!account) return;

      this.$emit('registered', {
        account,
        credentials: {
          email,
          password,
        },
      });

      this.$a.goal(this.$a.goals.UserRegister);

      if (!this.instantLogin) return;

      this.isLoading = true;

      const attempt = await this.$store.dispatch('auth/LoginAfterRegistration', {
        email,
        password,
      }).catch((e) => { this.error = e; });

      if (!attempt) { this.redirectToLogin(); return; }

      const res = await this.$store.dispatch('oauth2/password', {
        username: attempt.id,
        password: attempt.secret,
      });

      if (!res) { this.redirectToLogin(); return; }

      this.isLoading = false;

      this.$emit('logged-in', true);
    },
    redirectToLogin() {
      return this.$router.push(getResolvableLocaleLocation(this.$router, {
        name: 'auth.login',
        query: {
          redirect: this.$route.query.redirect || this.$route.fullPath,
        },
      }, this.$root.$i18n.locale as I18nLocale));
    },
  },
});
