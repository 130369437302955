


























import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  computed: {
    ...mapGetters({
      country: 'COUNTRY',
    }),
    defaultOption(): any {
      return {
        value: null,
        disabled: true,
        text: this.$t('select'),
      };
    },
    options(): any[] {
      const countries = this.$t('country');

      const list: any[] = [];

      Object.keys(countries).forEach((cc) => {
        if ([ // Exclude non-standard codes
          'null', 'AC', 'CP', 'DG', 'EA', 'EU', 'EZ',
          'FX', 'IC', 'TA', 'UK', 'UN', 'XX', 'ZZ', 'undefined',
        ].includes(cc)) return;
        list.push({
          value: cc,
          text: this.$t(`country.${cc}`),
        });
      });

      // Sort countries alphabetically in current language
      list.sort((a, b) => {
        if (a.text > b.text) return 1;
        if (a.text < b.text) return -1;
        return 0;
      });

      return list;
    },
  },
  methods: {
    change(country: string) {
      this.$store.commit('SetCountry', country);
    },
  },
});
